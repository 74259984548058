<template>
  <div class="confirm-dialog">
    <div class="confirm-dialog-content">
      <form>
        <div class="form-input-bloc">
          <h3 v-if="values.type === 0">
            {{ $t('settings.confirm.new_email') }}
          </h3>
          <h3 v-else>{{ $t('settings.confirm.new_phone') }}</h3>

          <el-input
            type="text"
            minlength="2"
            maxlength="75"
            :placeholder="values.newValue"
            :size="size"
            disabled
          />
        </div>

        <div class="form-input-bloc">
          <h3 v-if="values.type === 0">
            {{ $t('settings.confirm.code_email') }}
          </h3>
          <h3 v-else>{{ $t('settings.confirm.code_phone') }}</h3>

          <el-input
            type="text"
            minlength="6"
            maxlength="6"
            v-model="code"
            :size="size"
          />
        </div>

        <button
          @click.prevent="updateContactInformations()"
          class="generic-btn"
        >
          {{ $t('commons.continue') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  emits: ['close'],
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      size: 'large',

      code: null,
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
  },
  methods: {
    ...mapActions({
      getEmailPhoneCode: 'getEmailPhoneCode',
      confirmNewValue: 'confirmNewValue',
    }),
    async updateContactInformations() {
      const payloadEmail = {
        email_code: this.code,
        email: this.values.newValue,
      }

      const payloadPhone = {
        phone_code: this.code,
        phone: this.values.newValue,
      }

      if (this.values.type === 1) {
        await this.confirmNewValue(payloadPhone).then(() => {
          this.$emit('close');
        })
      } else {
        await this.confirmNewValue(payloadEmail).then(() => {
          this.$emit('close');
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.confirm-dialog {
  .confirm-dialog-content {

    form {
      display: flex;
      flex-direction: column;
      margin: 0;

      .form-input-bloc {
        margin-bottom: 24px;

        h3 {
          color: white;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }

      button {
        width: 125px;
        height: 47px;
        margin: auto;
      }
    }
  }
}
</style>
